export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAIL = 'AUTHENTICATE_USER_FAIL';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const GET_SESSION_DETAILS = 'GET_SESSION_DETAILS';
export const GET_SESSION_DETAILS_SUCCESS = 'GET_SESSION_DETAILS_SUCCESS';
export const GET_SESSION_DETAILS_FAIL = 'GET_SESSION_DETAILS_FAIL';
