import React from 'react';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from '../../store/actions/base-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function AlertComponent() {
  const containerRef = React.useRef(null);
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    alert: { show = false, message = '', variant = 'filled', severity = 'info' },
  } = useSelector((state) => {
    return state.base;
  });

  const handleClose = () => {
    dispatch({
      type: actionTypes.HIDE_ALERT,
    });
  };

  return (
    <>
      {show && Boolean(message) && (
        <div className={classes.root} ref={containerRef}>
          <Slide direction="up" in={show && Boolean(message)} container={containerRef.current}>
            <Alert variant={variant} severity={severity} onClose={handleClose}>
              {message}
            </Alert>
          </Slide>
        </div>
      )}
    </>
  );
}

export default AlertComponent;
