import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import { actionTypes } from '../../store/actions/base-actions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Notification() {
  const dispatch = useDispatch();

  const {
    notifications,
    showNotification = false,
    notificationPositionVertical = 'top',
    notificationPositionHorizontal = 'right'
  } = useSelector((state) => {
    return state.base;
  });

  const handleClose = () => {
    dispatch({
      type: actionTypes.HIDE_NOTIFICATION,
    });
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      {showNotification && notifications.length > 0 && (
        <Slide direction="up" in={showNotification} mountOnEnter unmountOnExit>
          <Snackbar
            open={showNotification}
            anchorOrigin={{ vertical: notificationPositionVertical, horizontal: notificationPositionHorizontal }}
            onClose={handleClose}
            autoHideDuration={notifications[0].autoHideDuration || 5000}
            key={'down'}
            action={action}
          >
            <Alert onClose={handleClose} severity={notifications[0].severity} sx={{ width: '100%' }}>
              {notifications[0].message}
            </Alert>
          </Snackbar>
        </Slide>
      )}
    </div>
  );
}

export default Notification;
