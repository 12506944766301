import Container from '@mui/material/Container';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPosts } from './posts-actions';

export default function Posts() {
  const dispatch = useDispatch();

  // const { posts, loadingPosts } = useSelector((state) => state.posts);

  useEffect(() => {
    dispatch(getPosts());
  }, []);

  return (
    <Container className="home">
      <h1>Posts</h1>
    </Container>
  );
}
