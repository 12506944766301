import { call, put, takeLatest } from 'redux-saga/effects';
import {
  SET_FILTERS,
  GET_CATEGORIES,
  GET_CATEGORIZATION_RULES,
  GET_TRANSACTIONS,
  FILTER_TRANSACTIONS,
  GET_REPORT_BY_CATEGORY,
  GET_TIMELINE_REPORT_ON_CATEGORY,
  updateFilters,
  setActionInProgress,
  actionFilterTransactions,
  getCategoriesSuccess,
  getCategorizationRulesSuccess,
  getTransactionsSuccess,
  fetchCurrentReportSuccess,
  UPSERT_CATEGORIZATION_RULES,
} from './expense-manager.reducer';
import {
  getCategories,
  getCategorizationRules,
  getTransactions,
  getReportByCategory,
  getTimelineReportOnCategory,
  postCategorizationRules,
} from './expense-manager.api';
import lodash from 'lodash';

function* sagaUpdateFilters({ data }) {
  console.log('sagaUpdateFilters called, data:', data);
  yield put(updateFilters({ ...data }));
}

function* sagaFilterTransactions() {
  console.log('sagaFilterTransactions called');
  yield put(setActionInProgress());
  yield put(actionFilterTransactions());
}

function* sagaGetCategories() {
  const getCategoriesResponse = yield call(getCategories);
  yield put(
    getCategoriesSuccess({
      categories: lodash.get(getCategoriesResponse, 'data.categories', []),
    }),
  );
}

function* sagaGetCategorizationRules() {
  const response = yield call(getCategorizationRules);
  yield put(
    getCategorizationRulesSuccess({
      categorizationRules: lodash.get(response, 'data.categorizationRules', []),
    }),
  );
}

function* sagaUpsertCategorizationRules(data) {
  yield call(postCategorizationRules, data);
}

function* sagaGetTransactions({ filters }) {
  console.log('sagaGetTransactions called filters:', filters);
  yield put(setActionInProgress());
  const { month, year } = filters;

  const getCategoriesResponse = yield call(getCategories);
  yield put(
    getCategoriesSuccess({
      categories: lodash.get(getCategoriesResponse, 'data.categories', []),
    }),
  );

  const getTransactionsResponse = yield call(getTransactions, {
    month,
    year,
  });
  const transactions = lodash.get(getTransactionsResponse, 'data.transactions', []);
  yield put(getTransactionsSuccess({ transactions }));
}

function* sagaGetReportByCategory({ filters }) {
  const { timePeriod } = filters;
  yield put(setActionInProgress());
  const reportResponse = yield call(getReportByCategory, { timePeriod });
  // console.log('sagaGetReportByCategory reportResponse:', reportResponse);
  yield put(
    fetchCurrentReportSuccess({
      reportData: lodash.get(reportResponse, 'data.reportData', []),
      transactionId: lodash.get(reportResponse, 'data.transactionId', ''),
    }),
  );
}

function* sagaGetTimelineReportOnCategory({ filters }) {
  console.log('sagaGetTimelineReportOnCategory called filters:', filters);
  const { timePeriod, category } = filters;
  yield put(setActionInProgress());
  const reportResponse = yield call(getTimelineReportOnCategory, { timePeriod, category });
  // console.log('sagaGetReportByCategory reportResponse:', reportResponse);
  yield put(
    fetchCurrentReportSuccess({
      reportData: lodash.get(reportResponse, 'data.reportData', []),
      transactionId: lodash.get(reportResponse, 'data.transactionId', ''),
    }),
  );
}

function* sagaExpenseManager() {
  yield takeLatest(SET_FILTERS, sagaUpdateFilters);
  yield takeLatest(GET_CATEGORIES, sagaGetCategories);
  yield takeLatest(GET_CATEGORIZATION_RULES, sagaGetCategorizationRules);
  yield takeLatest(GET_TRANSACTIONS, sagaGetTransactions);
  yield takeLatest(FILTER_TRANSACTIONS, sagaFilterTransactions);
  // yield takeLatest(GET_REPORT_BY_CATEGORY, sagaGetReportByCategory);
  yield takeLatest(GET_REPORT_BY_CATEGORY, sagaGetReportByCategory);
  yield takeLatest(GET_TIMELINE_REPORT_ON_CATEGORY, sagaGetTimelineReportOnCategory);
  yield takeLatest(UPSERT_CATEGORIZATION_RULES, sagaUpsertCategorizationRules);
}

export default sagaExpenseManager;
