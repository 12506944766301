/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import moment from 'moment';

export default function MonthYearSelector ({
  refreshData,
  month,
  year,
  handleDateChange
}) {
  const [data, setData] = useState({
    month: month || moment().format('MMM'),
    year: year || moment().format('YYYY')
  });

  const enableGoButton = data.month !== '' && data.year !== '';

  const handleDataChange = (e) => {
    console.log('handleDataChange e:', e.target.name, e.target.value);
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
    handleDateChange({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Stack flexDirection="row" justifyContent="flex-end">
      <Box width="150px">
        <FormControl sx={{
          m: 1,
          minWidth: 120
        }} size="small">
          <InputLabel id="select-month">Month</InputLabel>
          <Select labelId="select-month" id="select-month" value={month}
                  name="month" label="Month" onChange={handleDataChange}>
            <MenuItem value={'*'}>All months</MenuItem>
            <MenuItem value={'Jan'}>Jan</MenuItem>
            <MenuItem value={'Feb'}>Feb</MenuItem>
            <MenuItem value={'Mar'}>Mar</MenuItem>
            <MenuItem value={'Apr'}>Apr</MenuItem>
            <MenuItem value={'May'}>May</MenuItem>
            <MenuItem value={'Jun'}>Jun</MenuItem>
            <MenuItem value={'Jul'}>Jul</MenuItem>
            <MenuItem value={'Aug'}>Aug</MenuItem>
            <MenuItem value={'Sep'}>Sep</MenuItem>
            <MenuItem value={'Oct'}>Oct</MenuItem>
            <MenuItem value={'Nov'}>Nov</MenuItem>
            <MenuItem value={'Dec'}>Dec</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box width="150px">
        <FormControl sx={{
          m: 1,
          minWidth: 120
        }} size="small">
          <InputLabel id="select-year">Year</InputLabel>
          <Select
            labelId="select-year"
            id="select-year"
            value={year}
            name="year"
            label="Year"
            onChange={handleDataChange}
          >
            <MenuItem value="">
              <em>Select year</em>
            </MenuItem>
            <MenuItem value={'2021'}>2021</MenuItem>
            <MenuItem value={'2022'}>2022</MenuItem>
            <MenuItem value={'2023'}>2023</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Box paddingTop="0.6rem">
          <Button variant="contained" disabled={!enableGoButton}
                  onClick={() => refreshData()}>
            Go
          </Button>
        </Box>
      </Box>
    </Stack>
  );
}
