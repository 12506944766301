/* eslint-disable react/react-in-jsx-scope */
import dayjs from 'dayjs';

export const transactionsTableColumns = [
  { field: 'account_name', headerName: 'Account', width: 350 },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    // editable: true,
    type: 'number',
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 250,
    editable: false,
  },
  /* {
    field: 'classification',
    headerName: 'Classification',
    width: 110,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 20,
  },
  {
    field: 'dataset_id',
    headerName: 'Dataset ID',
    width: 110,
    // editable: true,
  }, */
  {
    field: 'original_description',
    headerName: 'Original Description',
    width: 200,
    // editable: true,
  },
  /* {
    field: 'simple_description',
    headerName: 'Simple Description',
    width: 110,
    // editable: true,
  }, */
  {
    field: 'transaction_date',
    headerName: 'Transaction Date',
    width: 110,
  },
];

export const categoriesTableColumns = [
  { field: 'category_name', headerName: 'Category name', width: 450 },
  {
    field: 'active',
    headerName: 'active',
    width: 150,
    type: 'boolean',
  },
  {
    field: 'category_id',
    headerName: 'Category id',
    width: 350,
    editable: false,
  },
];

export const categorizationRulesTableColumns = [
  {
    field: 'rule_type',
    headerName: 'Rule type',
    width: 250,
    type: 'singleSelect',
    valueOptions: ['EQUALS', 'STARTS_WITH', 'CONTAINS'],
    editable: true,
  },
  { field: 'rule_text', headerName: 'Rule', editable: true, width: 450 },
  { field: 'category', headerName: 'Category', editable: true, width: 300 },
  {
    field: 'active',
    headerName: 'active',
    width: 100,
    type: 'boolean',
  },
];

export const buildCategorizationRulesTableColumns = (categories) => {
  return [
    {
      field: 'rule_type',
      headerName: 'Rule type',
      width: 250,
      type: 'singleSelect',
      valueOptions: ['EQUALS', 'STARTS_WITH', 'CONTAINS'],
      editable: true,
    },
    { field: 'rule_text', headerName: 'Rule', editable: true, width: 400 },
    {
      field: 'category',
      headerName: 'Category',
      editable: true,
      width: 300,
      type: 'singleSelect',
      valueOptions: categories.map((category) => category.category_name),
    },
    {
      field: 'active',
      headerName: 'active',
      width: 100,
      type: 'boolean',
      editable: true,
    },
  ];
};

export const chartColors = [
  'rgba(255, 99, 132, 0.6)',
  'rgba(54, 162, 235, 0.6)',
  'rgba(255, 206, 86, 0.6)',
  'rgba(75, 192, 192, 0.6)',
  'rgba(153, 102, 255, 0.6)',
  'rgba(255, 159, 64, 0.6)',
  'rgba(255, 99, 71, 0.6)',
  'rgba(72, 209, 204, 0.6)',
  'rgba(128, 0, 128, 0.6)',
  'rgba(218, 165, 32, 0.6)',
  'rgba(154, 205, 50, 0.6)',
  'rgba(238, 130, 238, 0.6)',
  'rgba(135, 206, 250, 0.6)',
  'rgba(255, 0, 255, 0.6)',
  'rgba(176, 196, 222, 0.6)',
  'rgba(70, 130, 180, 0.6)',
  'rgba(240, 128, 128, 0.6)',
  'rgba(0, 255, 127, 0.6)',
  'rgba(255, 255, 0, 0.6)',
  'rgba(0, 255, 255, 0.6)',
  'rgba(218, 112, 214, 0.6)',
  'rgba(32, 178, 170, 0.6)',
  'rgba(255, 165, 0, 0.6)',
  'rgba(46, 139, 87, 0.6)',
  'rgba(220, 20, 60, 0.6)',
  'rgba(128, 128, 128, 0.6)',
  'rgba(0, 0, 255, 0.6)',
  'rgba(0, 128, 128, 0.6)',
  'rgba(210, 180, 140, 0.6)',
  'rgba(0, 128, 0, 0.6)',
];

export const chartBorderColors = [
  'rgba(255, 99, 132)',
  'rgba(54, 162, 235)',
  'rgba(255, 206, 86)',
  'rgba(75, 192, 192)',
  'rgba(153, 102, 255)',
  'rgba(255, 159, 64)',
  'rgba(255, 99, 71)',
  'rgba(72, 209, 204)',
  'rgba(128, 0, 128)',
  'rgba(218, 165, 32)',
  'rgba(154, 205, 50)',
  'rgba(238, 130, 238)',
  'rgba(135, 206, 250)',
  'rgba(255, 0, 255)',
  'rgba(176, 196, 222)',
  'rgba(70, 130, 180)',
  'rgba(240, 128, 128)',
  'rgba(0, 255, 127)',
  'rgba(255, 255, 0)',
  'rgba(0, 255, 255)',
  'rgba(218, 112, 214)',
  'rgba(32, 178, 170)',
  'rgba(255, 165, 0)',
  'rgba(46, 139, 87)',
  'rgba(220, 20, 60)',
  'rgba(128, 128, 128)',
  'rgba(0, 0, 255)',
  'rgba(0, 128, 128)',
  'rgba(210, 180, 140)',
  'rgba(0, 128, 0)',
];

export function searchTransactions({ transactions, searchString, categoryFilter = [] }) {
  console.log('searchTransactions searchString:', searchString, 'categoryFilter:', categoryFilter);
  if (!searchString.trim() && categoryFilter.length === 0) {
    return transactions;
  }

  return transactions.filter((transaction) => {
    const { simple_description: simpleDescription, original_description: originalDescription } = transaction;
    let status = false;
    if (searchString.trim().length !== 0) {
      status =
        simpleDescription.toLowerCase().includes(searchString.toLowerCase()) ||
        originalDescription.toLowerCase().includes(searchString.toLowerCase());
    }

    if (categoryFilter.length > 0) {
      status = categoryFilter.includes(transaction.category);
    }
    return status;
  });
}

export function createReportData({ inputData }) {
  // console.log('createReportData inputData:', inputData);
  if (!inputData || inputData.length === 0) {
    return null;
  }

  return {
    labels: inputData.map((row) => row.category),
    datasets: [
      {
        label: 'Category',
        data: inputData.map((row) => row.category_total),
        backgroundColor: chartColors,
        borderColor: chartBorderColors,
      },
    ],
  };
}

export const timePeriods = [
  {
    label: 'Current month',
    value: 'current-month',
  },
  {
    label: 'Last month',
    value: 'last-month',
  },
  {
    label: 'This quarter',
    value: 'this-quarter',
  },
  {
    label: 'Last quarter',
    value: 'last-quarter',
  },
  {
    label: 'This year',
    value: 'this-year',
  },
  {
    label: 'Last 12 months',
    value: 'last-12-months',
  },
  {
    label: 'Last year',
    value: 'last-year',
  },
  {
    label: 'two-years-ago',
    value: dayjs().subtract(2, 'year').format('YYYY'),
  },
  {
    label: 'three-years-ago',
    value: dayjs().subtract(3, 'year').format('YYYY'),
  },
  {
    label: 'four-years-ago',
    value: dayjs().subtract(4, 'year').format('YYYY'),
  },
  {
    label: 'five-years-ago',
    value: dayjs().subtract(5, 'year').format('YYYY'),
  },
  {
    label: 'All Years',
    value: 'all-years',
  },
];
