/* eslint-disable react/prop-types */
import React, { useRef } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

export function HorizontalBarChart({ data, options, handleOnClick }) {
  const chartRef = useRef();

  const onClick = (event) => {
    const elementAtEvent = getElementAtEvent(chartRef.current, event);
    if (typeof handleOnClick === 'function') {
      handleOnClick({ elementAtEvent, category: data.labels[elementAtEvent[0].index] });
    }
  };

  return <Bar ref={chartRef} options={options} data={data} onClick={onClick} />;
}
