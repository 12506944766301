export const actionTypes = {
  FAILURE: 'FAILURE',
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  RESET: 'RESET',
  LOAD_DATA: 'LOAD_DATA',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  START_CLOCK: 'START_CLOCK',
  TICK_CLOCK: 'TICK_CLOCK',
  HYDRATE: 'HYDRATE',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',
  SHOW_BUSY_LOADER: 'SHOW_BUSY_LOADER',
  HIDE_BUSY_LOADER: 'HIDE_BUSY_LOADER'
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE,
    error,
  };
}

export function showAlertMessage({ message, variant = '', severity = '' }) {
  return {
    type: actionTypes.SHOW_ALERT,
    payload: { variant, message, severity },
  };
}

export function loadDataSuccess(data) {
  return {
    type: actionTypes.LOAD_DATA_SUCCESS,
    data,
  };
}

export function tickClock(isServer) {
  return {
    type: actionTypes.TICK_CLOCK,
    light: !isServer,
    ts: Date.now(),
  };
}

export function showBusyLoader() {
  return {
    type: actionTypes.SHOW_BUSY_LOADER,
  };
}

export function hideBusyLoader() {
  return {
    type: actionTypes.HIDE_BUSY_LOADER,
  };
}
