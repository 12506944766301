import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { apiUpdateTransactionCategories } from '../expense-manager.api';
import { SET_FILTERS, GET_TRANSACTIONS, FILTER_TRANSACTIONS } from '../expense-manager.reducer';
import { debounce, chain } from 'lodash';
import { showBusyLoader, hideBusyLoader } from '../../../store/actions/base-actions';
import NavBar from './nav-bar';
import TransactionsTable from '../components/transactions-table';

const ReviewTransactions = () => {
  const [searchString, setSearchString] = useState('');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const dispatch = useDispatch();
  const {
    filters: globalFilters,
    filteredTransactions,
    actionInProgress,
    categories: allCategories,
    // actionCompleted,
    // error
  } = useSelector((state) => state.expenseManager);
  console.log('ReviewTransactions globalFilters:', globalFilters);

  const handleCategoryFilterChange = (categories) => {
    dispatch({ type: SET_FILTERS, data: { categories, filterData: true } });
    dispatch({ type: FILTER_TRANSACTIONS });
  };

  const handleSearchStringChange = debounce((e) => {
    // dispatch(setActionInProgress());
    setSearchString(e.target.value);
    dispatch({ type: SET_FILTERS, data: { searchString: e.target.value, filterData: true } });
    dispatch({ type: FILTER_TRANSACTIONS });
    // filterTransactions({ searchString: e.target.value });
  }, 1000);

  const updateTransactionCategories = async (category) => {
    dispatch(showBusyLoader());
    const resp = await apiUpdateTransactionCategories({
      transactionIds: rowSelectionModel,
      category: category.title,
    });
    console.log('updateTransactionCategories resp:', resp);
    fetchTransactions();
    dispatch(hideBusyLoader());
  };

  function fetchTransactions() {
    dispatch({ type: GET_TRANSACTIONS, filters: { ...globalFilters } });
  }

  useEffect(() => {
    dispatch({ type: GET_TRANSACTIONS, filters: { ...globalFilters } });
  }, []);

  const handleDateChange = (data) => {
    dispatch({ type: SET_FILTERS, data: { ...data, filterData: false } });
  };

  return (
    <>
      <h1> Review Transactions</h1>
      <NavBar
        categories={chain(allCategories).map('category_name').uniq().sort().value()}
        month={globalFilters.month}
        year={globalFilters.year}
        searchString={searchString}
        handleSearchStringChange={handleSearchStringChange}
        handleDateChange={handleDateChange}
        handleCategoryFilterChange={handleCategoryFilterChange}
        refreshData={fetchTransactions}
        actionInProgress={actionInProgress}
        showUpdateButton={rowSelectionModel.length > 0}
        handleSubmitCategoryUpdate={updateTransactionCategories}
      />
      <Box sx={{ height: 'calc(100vh - 350px)', width: '100%', marginBottom: '2rem' }}>
        <TransactionsTable
          transactions={filteredTransactions}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          checkboxSelection={true}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </>
  );
};

export default ReviewTransactions;
