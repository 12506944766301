import { call, put, takeLatest } from 'redux-saga/effects';

import { AUTHENTICATE_USER, GET_SESSION_DETAILS, LOGOUT_USER } from './auth.actionTypes';
import { showAlertMessage } from '../../store/actions/base-actions';

import {
  authenticateUserFail,
  authenticateUserSuccess,
  getSessionDetailsFail,
  getSessionDetailsSuccess,
  logoutUserSuccess,
} from './auth.actions';

import {
  authenticateUser as authenticateUserAPI,
  getSessionDetails as getSessionDetailsAPI,
  logoutUser as logoutUserAPI,
} from './auth.api';
import { csrfTokenHeaderName } from '../../helpers/api-helper';

function* authenticateUser(action) {
  try {
    const response = yield call(authenticateUserAPI, action.payload);
    yield put(authenticateUserSuccess(response.data));
  } catch (error) {
    yield put(authenticateUserFail(error.response));
    yield put(
      showAlertMessage({
        message: 'authentication failed',
        severity: 'error',
        variant: 'filled',
      }),
    );
  }
}

function* onSessionDetails() {
  try {
    const response = yield call(getSessionDetailsAPI);
    yield put(getSessionDetailsSuccess(response.data));
  } catch (error) {
    yield put(getSessionDetailsFail(error.response));
  }
}

function* logoutUser(action) {
  try {
    yield call(logoutUserAPI);
    localStorage.removeItem(csrfTokenHeaderName);
    yield put(logoutUserSuccess());
    action.navigate('/login');
  } catch (error) {
    // yield put(getSessionDetailsFail(error.response));
  }
}

function* AuthSaga() {
  yield takeLatest(AUTHENTICATE_USER, authenticateUser);
  yield takeLatest(GET_SESSION_DETAILS, onSessionDetails);
  yield takeLatest(LOGOUT_USER, logoutUser);
}

export default AuthSaga;
