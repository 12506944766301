/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { timePeriods } from '../../pages/expense-manager/utils';

export default function ReportTimePeriodSelector ({
  refreshData,
  defaultTimePeriod = 'current-month',
  excludeTimePeriods = []
}) {
  const timePeriodsToDisplay = timePeriods.filter((timePeriod) => !excludeTimePeriods.includes(timePeriod.label));
  const [timePeriod, setTimePeriod] = useState(timePeriodsToDisplay[0].value);

  const handleDataChange = (e) => {
    setTimePeriod(e.target.value);
    refreshData({
      timePeriod: e.target.value
    });
  };

  return (
    <Stack flexDirection="row" justifyContent="flex-end">
      <FormControl sx={{
        mx: 4,
        minWidth: 120
      }} size="small">
        <InputLabel id="time-period">Time period</InputLabel>
        <Select labelId="time-period" id="time-period" value={timePeriod}
                name="time-period" label="Time period"
                onChange={handleDataChange}>
          {
            timePeriodsToDisplay.map((timePeriod) => (
                <MenuItem key={timePeriod.label}
                          value={timePeriod.value}
                >
                  {timePeriod.label}
                </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <Box>
        <Button variant="contained" onClick={() => refreshData()}>
          Go
        </Button>
      </Box>
    </Stack>
  );
}
