import axiosApi, { api } from '../../helpers/api-helper';

export const uploadFiles = async ({ formData }) => {
  try {
    return await axiosApi.post(`${api.EXPENSES_API_ROOT_URL}/load-transactions`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const getTransactions = async ({ month, year }) => {
  try {
    return await axiosApi.get(`${api.EXPENSES_API_ROOT_URL}/transactions`, {
      params: { month, year },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const getCategories = async () => {
  try {
    return await axiosApi.get(`${api.EXPENSES_API_ROOT_URL}/categories`);
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const getCategorizationRules = async () => {
  try {
    return await axiosApi.get(`${api.EXPENSES_API_ROOT_URL}/categorization-rules`);
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const postCategorizationRules = async ({ data }) => {
  try {
    return await axiosApi.post(`${api.EXPENSES_API_ROOT_URL}/categorization-rules`, {
      ...data,
    });
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const apiUpdateTransactionCategories = async ({ category, transactionIds }) => {
  console.log('apiUpdateTransactionCategories - category:', category);
  console.log('apiUpdateTransactionCategories - transactionIds:', transactionIds);
  try {
    return await axiosApi.post(`${api.EXPENSES_API_ROOT_URL}/update-transaction-categories`, {
      category,
      transactionIds,
    });
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const getReportByCategory = async ({ timePeriod }) => {
  try {
    return await axiosApi.get(`${api.EXPENSES_API_ROOT_URL}/report-by-category`, {
      params: { timePeriod },
    });
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};

export const getTimelineReportOnCategory = async ({ timePeriod, category }) => {
  try {
    return await axiosApi.get(`${api.EXPENSES_API_ROOT_URL}/timeline-report-on-category`, {
      params: { timePeriod, category },
    });
  } catch (err) {
    return { error: err.message, statusText: 'Request failed' };
  }
};
