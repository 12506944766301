import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { csrfTokenHeaderName } from '../../helpers/api-helper';

function ProtectedRoute({ children }) {
  const location = useLocation();
  const isAuthenticated = localStorage.getItem(csrfTokenHeaderName);

  return isAuthenticated ? children : <Navigate to="/login" replace state={{ path: location.pathname }} />;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
