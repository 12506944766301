import { actionTypes } from '../actions/base-actions';

const initialState = {
  count: 0,
  error: false,
  lastUpdate: 0,
  light: false,
  placeholderData: null,
  showNotification: false,
  notifications: [],
  notificationPositionVertical: 'top',
  notificationPositionHorizontal: 'right',
  alert: {
    show: false,
    message: '',
    variant: 'filled',
    severity: 'info',
  },
  showAlert: false,
  alertMessage: '',
  alertVariant: 'filled',
  alertSeverity: 'info',
  showBusyLoader: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_BUSY_LOADER:
      state = {
        ...state,
        showBusyLoader: true,
      };
      break;
    case actionTypes.HIDE_BUSY_LOADER:
      state = {
        ...state,
        showBusyLoader: false,
      };
      break;
    case actionTypes.SHOW_NOTIFICATION:
      state = {
        ...state,
        showNotification: true,
        notifications: [
          {
            message: action.payload.message,
            severity: action.payload.severity,
            autoHideDuration: action.payload.autoHideDuration || 5000,
          },
        ],
        notificationPositionVertical: action.payload.vertical || 'top',
        notificationPositionHorizontal: action.payload.horizontal || 'right',
      };
      break;
    case actionTypes.HIDE_NOTIFICATION:
      state = {
        ...state,
        showNotification: false,
        notifications: [],
      };
      break;
    case actionTypes.SHOW_ALERT:
      state = {
        ...state,
        alert: {
          show: true,
          message: action.payload.message,
          variant: action.payload.variant || 'filled',
          severity: action.payload.severity || 'info',
        },
      };
      break;
    case actionTypes.HIDE_ALERT:
      state = {
        ...state,
        alert: {
          show: false,
          message: '',
        },
      };
      break;
    case actionTypes.FAILURE:
      return {
        ...state,
        ...{ error: action.error },
      };
    case actionTypes.INCREMENT:
      return {
        ...state,
        ...{ count: state.count + 1 },
      };
    case actionTypes.DECREMENT:
      return {
        ...state,
        ...{ count: state.count - 1 },
      };
    case actionTypes.RESET:
      return {
        ...state,
        ...{ count: initialState.count },
      };
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...{ placeholderData: action.data },
      };
    case actionTypes.TICK_CLOCK:
      return {
        ...state,
        ...{ lastUpdate: action.ts, light: !!action.light },
      };
    default:
      return state;
  }

  return state;
}

export default reducer;
