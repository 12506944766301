import axiosApi, { api } from '../../helpers/api-helper';
import moment from 'moment';

export const apiGetHealthLogData = async ({ date }) => {
  return await axiosApi.get(`${api.HEALTH_LOG_API_ROOT_URL}/get-data`, {
    params: {
      date: moment(date).format('YYYY-MM-DD'),
    },
  });
};

export const apiSaveHealthLog = async ({ date, fieldName, text }) => {
  return await axiosApi.post(`${api.HEALTH_LOG_API_ROOT_URL}/save-field`, {
    date,
    fieldName,
    text,
  });
};
