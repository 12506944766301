import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_POSTS, GET_POST_DETAILS } from './posts-actionTypes';

import { actionTypes } from '../../store/actions/base-actions';

import { getPostDetailsFail, getPostDetailsSuccess, getPostsFail, getPostsSuccess } from './posts-actions';

import { getPostDetails, getPosts } from '../../helpers/backend-helper';

function* onGetPosts() {
  try {
    const response = yield call(getPosts);
    yield put(getPostsSuccess(response));
    yield put({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: {
        notificationMessages: [
          {
            message: 'Fetched new posts',
          },
        ],
      },
    });
  } catch (error) {
    yield put(getPostsFail(error.response));
  }
}

function* onGetPostDetails({ payload: id }) {
  try {
    const response = yield call(getPostDetails, id);
    yield put(getPostDetailsSuccess(response));
  } catch (error) {
    yield put(getPostDetailsFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(GET_POSTS, onGetPosts);
  yield takeLatest(GET_POST_DETAILS, onGetPostDetails);
}

export default CartSaga;
