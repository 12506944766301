import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from '../../store/actions/base-actions';
import { BallTriangle } from 'react-loader-spinner';

export default function LoadingSpinner() {
  const dispatch = useDispatch();
  const {
    showBusyLoader = false,
  } = useSelector((state) => {
    return state.base;
  });

  const handleClose = () => {
    dispatch({
      type: actionTypes.HIDE_BUSY_LOADER,
    });
  };

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBusyLoader}
        onClick={handleClose}
      >
        <BallTriangle
          height={200}
          width={200}
          radius={5}
          color="red"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={showBusyLoader}
        />
      </Backdrop>
    </div>
  );
}
