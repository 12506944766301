import React, { useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesTableColumns, buildCategorizationRulesTableColumns } from '../utils';
// import { postCategorizationRules } from '../expense-manager.api';
import { GET_CATEGORIES, GET_CATEGORIZATION_RULES, UPSERT_CATEGORIZATION_RULES } from '../expense-manager.reducer';
import { actionTypes } from '../../../store/actions/base-actions';

const Categories = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState('1');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { categories: allCategories, categorizationRules } = useSelector((state) => state.expenseManager);

  useEffect(() => {
    dispatch({ type: GET_CATEGORIES });
    dispatch({ type: GET_CATEGORIZATION_RULES });
  }, []);

  const handleCategorizationRuleUpdate = useCallback(async (params) => {
    /* try {
      const result = await postCategorizationRules(params);
      console.log('handleCategorizationRuleUpdate result:', result);
      if (result.error) {
        throw new Error(result.error.statusText);
      }

      dispatch({
        type: actionTypes.SHOW_NOTIFICATION,
        payload: {
          message: 'Successfully updated the categorization rule',
          severity: 'success',
          autoHideDuration: 5000,
          vertical: 'top',
          horizontal: 'center',
        },
      });
      dispatch({ type: GET_CATEGORIZATION_RULES });
      return params;
    } catch (err) {
      dispatch({
        type: actionTypes.SHOW_NOTIFICATION,
        payload: {
          message: 'Failed to update the categorization rule',
          severity: 'error',
          autoHideDuration: 5000,
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } */
    dispatch({ type: UPSERT_CATEGORIZATION_RULES, data: { ...params } });
    return params;
  }, []);

  const handleCategorizationRuleUpdateFail = useCallback(() => {
    dispatch({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: {
        message: 'Failed to update the categorization rule',
        severity: 'error',
        autoHideDuration: 5000,
        vertical: 'top',
        horizontal: 'center',
      },
    });
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <h1>Categories</h1>
      <TabContext value={tabValue}>
        <Box sx={{}}>
          <TabList onChange={handleTabChange} aria-label="Categorization tabs">
            <Tab label="Manage categories" value="1" />
            <Tab label="Categorization rules" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ height: 'calc(100vh - 350px)', width: '100%', marginBottom: '2rem' }}>
            <DataGrid
              rows={allCategories}
              columns={categoriesTableColumns}
              getRowId={(row) => row.category_id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
              onCellEditStop={(params, event, details) => {
                console.log('categories onCellEditStop params:', params);
                console.log('categories onCellEditStop event:', event);
                console.log('categories onCellEditStop details:', details);
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box sx={{ height: 'calc(100vh - 350px)', width: '100%', marginBottom: '2rem' }}>
            <DataGrid
              rows={categorizationRules}
              columns={buildCategorizationRulesTableColumns(allCategories)}
              getRowId={(row) => row.rule_id}
              getRowClassName={(params) => (params.row.active ? '' : 'disabled')}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
              editMode={'row'}
              onCellEditStop={(params, event, details) => {
                console.log('categorizationRules onCellEditStop params:', params);
                console.log('categorizationRules onCellEditStop event:', event);
                console.log('categorizationRules onCellEditStop details:', details);
              }}
              processRowUpdate={handleCategorizationRuleUpdate}
              onProcessRowUpdateError={handleCategorizationRuleUpdateFail}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default Categories;
