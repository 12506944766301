import Container from '@mui/material/Container';
import React from 'react';
// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

export default function WaterUsage() {
  return (
    <Container
      className="home"
      style={{
        border: '2px dashed red',
        marginTop: '100px',
        background: 'red',
      }}
    >
      <h1>Water Usage</h1>
    </Container>
  );
}
