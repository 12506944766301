/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import FormControl from '@mui/material/FormControl';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import { v4 as uuidV4 } from 'uuid';

const HealthLogInput = ({
  rows = 4,
  name,
  label,
  onChange,
  defaultValue,
  saveInProgress = false,
  saveFailed = false,
  errorMessage = '',
}) => {
  const [fieldValue, setFieldValue] = useState(defaultValue);

  useEffect(() => {
    setFieldValue(defaultValue);
  }, [defaultValue]);

  const onFieldValueChange = debounce((e) => {
    setFieldValue(e.target.value);
    onChange(e);
    document.querySelector(`textarea[name="${e.target.name}"]`).focus();
  }, 1000);

  let statusIcon;

  if (saveFailed) {
    statusIcon = (
      <Tooltip title={errorMessage}>
        <ErrorIcon color="error" />
      </Tooltip>
    );
  } else {
    statusIcon =
      defaultValue && defaultValue.length > 0 ? saveInProgress ? <CircularProgress /> : <DownloadDoneIcon /> : null;
  }

  return (
    <TextField
      fullWidth
      multiline
      variant={'filled'}
      rows={rows}
      label={label}
      placeholder={label}
      defaultValue={fieldValue}
      name={name}
      id={name}
      onChange={onFieldValueChange}
      key={uuidV4()}
      autoComplete="off"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: statusIcon,
      }}
      sx={{ mt: 4 }}
    />
  );
};

export default HealthLogInput;
