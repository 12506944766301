import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_SUCCESS,
  GET_SESSION_DETAILS,
  GET_SESSION_DETAILS_FAIL,
  GET_SESSION_DETAILS_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
} from './auth.actionTypes';

export const authenticateUser = ({ username, password }) => {
  return {
    type: AUTHENTICATE_USER,
    payload: { username, password },
  };
};

export const authenticateUserSuccess = (data) => {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    payload: data,
  };
};

export const authenticateUserFail = (error) => {
  return {
    type: AUTHENTICATE_USER_FAIL,
    payload: error,
  };
};

export const getSessionDetails = () => {
  return {
    type: GET_SESSION_DETAILS,
  };
};

export const getSessionDetailsSuccess = (sessionDetails) => {
  return {
    type: GET_SESSION_DETAILS_SUCCESS,
    payload: sessionDetails,
  };
};

export const getSessionDetailsFail = (error) => {
  return {
    type: GET_SESSION_DETAILS_FAIL,
    payload: error,
  };
};

export const logoutUser = (navigate) => {
  return {
    type: LOGOUT_USER,
    navigate,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};
