import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  showBusyLoader,
  hideBusyLoader,
  actionTypes
} from '../../../store/actions/base-actions';
// import { styled } from '@mui/material/styles';
import { uploadFiles } from '../expense-manager.api';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

const containerVariants = {
  hidden: {
    x: '-100vw'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      delay: 0.25
    }
  },
  exit: {
    x: '+100vh',
    transition: { ease: 'easeInOut' }
  }
};

const UploadFiles = () => {
  const dispatch = useDispatch();
  const fileRef = React.useRef();
  const acceptedFormats = '.csv';

  const [selectedFiles, setSelectedFiles] = React.useState();

  const handleFileSelect = (event) => {
    setSelectedFiles(event?.target?.files?.[0]);
  };

  const onUpload = async () => {
    const formData = new FormData();
    formData.append('files', selectedFiles);

    dispatch(showBusyLoader());
    const response = await uploadFiles({ formData });
    if (response && response.statusText === 'OK') {
      dispatch(hideBusyLoader());
      dispatch({
        type: actionTypes.SHOW_NOTIFICATION,
        payload: {
          message: `Successfully loaded the file "${selectedFiles.name}"`,
          severity: 'success',
          autoHideDuration: 5000,
          vertical: 'top',
          horizontal: 'center'
        }
      });
    } else {
      dispatch(hideBusyLoader());
      dispatch({
        type: actionTypes.SHOW_NOTIFICATION,
        payload: {
          message: `Failed to upload the file "${selectedFiles.name}"`,
          severity: 'warning',
          autoHideDuration: 5000
        }
      });
    }
  };

  const onClear = () => {
    setSelectedFiles(undefined);
  };

  /* const onUpdate = (event) => {
    if (event.target.textContent.trim().toLowerCase() === 'change') {
      onClear();
      fileRef.current.click();
      return;
    }
    if (event.target.textContent.trim().toLowerCase() === 'clear') {
      onClear();
    }
  }; */

  return (
    <AnimatePresence mode={'wait'}>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <h1> Upload Files </h1>
        <Container
          sx={{
            width: {
              sx: '100%',
              md: '700px'
            },
            minHeight: '3rem',
            height: 'auto',
            border: '0 dashed gray'
          }}
        >
          {selectedFiles?.name ? (
            <Stack
              direction="row"
              alignContent="center"
              justifyContent="space-between"
              spacing={1}
              padding=".5rem"
              mb="2rem"
              sx={{
                border: '1px solid #ddd',
                borderRadius: '.25rem',
                background: '#f8f8f8'
              }}
            >
              <Box
                w={'320px'}
                sx={{
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
                color="text.primary"
              >
                {selectedFiles?.name}
              </Box>
              <Box
                w={'50px'}
                sx={{
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <IconButton color="primary" aria-label="remove file"
                            component="label" onClick={onClear}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Stack>
          ) : null}
          <Box>
            <input ref={fileRef} hidden type="file" accept={acceptedFormats}
                   onChange={handleFileSelect} />
          </Box>

          <Box>
            {!selectedFiles?.name && (
              <Button
                variant="contained"
                component="label"
                style={{ textTransform: 'none' }}
                onClick={() => fileRef.current?.click()}
              >
                Choose file to upload
              </Button>
            )}
            {selectedFiles?.name && (
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedFiles} style={{ textTransform: 'none' }}
                onClick={onUpload}
              >
                Upload
              </Button>
            )}
          </Box>
        </Container>

        {/* {selectedFiles?.name && (
        <Button variant="contained" component="label" style={{ textTransform: 'none' }} onClick={onUpdate}>
          <span style={{ float: 'left' }}> {selectedFiles?.name}</span>
          <span style={{ padding: '10px' }}> Change</span>
          <span>Clear</span>
        </Button>
      )} */}
      </motion.div>
    </AnimatePresence>
  );
};

export default UploadFiles;
