import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_SUCCESS,
  GET_SESSION_DETAILS,
  GET_SESSION_DETAILS_FAIL,
  GET_SESSION_DETAILS_SUCCESS,
  LOGOUT_USER_SUCCESS,
} from './auth.actionTypes';

const initialState = {
  sessionDetails: {},
  authenticatingUser: false,
  gettingSessionDetails: false,
  error: {
    message: '',
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      state = { ...state, authenticatingUser: true };
      break;
    case AUTHENTICATE_USER_SUCCESS:
      state = {
        ...state,
        sessionDetails: action.payload.sessionDetails,
        authenticatingUser: false,
      };
      break;
    case AUTHENTICATE_USER_FAIL:
      state = {
        ...state,
        error: {
          message: 'Error',
        },
        authenticatingUser: false,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        sessionDetails: {},
      };
      break;
    case GET_SESSION_DETAILS:
      state = { ...state, gettingSessionDetails: true };
      break;
    case GET_SESSION_DETAILS_SUCCESS:
      state = {
        ...state,
        sessionDetails: action.payload.sessionDetails,
        gettingSessionDetails: false,
      };
      break;
    case GET_SESSION_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: 'Error',
        },
        gettingSessionDetails: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authReducer;
