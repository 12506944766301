import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { getSessionDetails } from '../../pages/auth/auth.actions';
import { csrfTokenHeaderName } from '../../helpers/api-helper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appTitle: {
    flexGrow: 1,
  },
  userDetails: {
    height: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const csrfToken = localStorage.getItem(csrfTokenHeaderName);

  const {
    auth: { sessionDetails },
  } = useSelector((state) => state);
  // console.log('NavBar sessionDetails:', sessionDetails);

  useEffect(() => {
    if (csrfToken && (!sessionDetails || !get(sessionDetails, 'user_id'))) {
      dispatch(getSessionDetails());
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.appTitle}>
            Kamidi App
          </Typography>
          <Link to="/expense-manager">
            <Button color="inherit">Expense Manager</Button>
          </Link>
          <Link to="/data-feeds">
            <Button color="inherit">Data Feeds</Button>
          </Link>
          <Link to="/">
            <Button color="inherit">Home</Button>
          </Link>
          {!csrfToken && (
            <Link to="/login">
              <Button color="inherit">Login</Button>
            </Link>
          )}
          {Boolean(csrfToken) && (
            <Link to="/logout">
              <Button color="inherit">Logout</Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>

      {sessionDetails && sessionDetails.username && (
        <div className={classes.userDetails}>
          <span>{'User Id:'}&nbsp;&nbsp;</span>
          <span>
            <strong>{`${get(sessionDetails, 'username')}`}</strong>
          </span>
        </div>
      )}
    </div>
  );
}
