import * as url from './url-helper';
import axiosApi from '../helpers/api-helper';

export const getPosts = () => {
  return axiosApi.get('/users');
};

export const getPostDetails = (id) => {
  return axiosApi.get(url.GET_POST_DETAILS, {
    params: {
      id,
    },
  });
};
