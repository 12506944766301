import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

export const GET_HEALTH_LOG_DATA = 'GET_HEALTH_LOG_DATA';
export const SAVE_HEALTH_LOG = 'SAVE_HEALTH_LOG';
// export const SAVE_HEALTH_LOG_SUCCESS = 'SAVE_HEALTH_LOG_SUCCESS';
// export const SAVE_HEALTH_LOG_FAIL = 'SAVE_HEALTH_LOG_FAIL';

const emptyFormData = {
  date: '',
  morning_status: '',
  sleep_pattern: '',
  breakfast: '',
  lunch: '',
  dinner: '',
  medicines_taken: '',
  multivitamins_taken: '',
  exercise: '',
  feeling_throughout_day: '',
  stress_level: '',
  feeling_at_bedtime: '',
};

const initialState = {
  formData: { ...emptyFormData },
  saveActionInProgress: false,
  saveActionCompleted: false,
  saveInProgressForFormField: '',
  error: {
    message: '',
  },
  failedRequests: {},
};

const fetchPropertyValue = (action, propertyName) => {
  return get(action, `payload.data.${propertyName}`, '');
};

export const dailyHealthLogSlice = createSlice({
  name: 'dailyHealthLog',
  initialState,
  reducers: {
    getHealthLog: (state, action) => {
      state.formData = {
        date: action.payload.data.entry_date,
        morning_status: fetchPropertyValue(action, 'morning_status'),
        sleep_pattern: fetchPropertyValue(action, 'sleep_pattern'),
        breakfast: fetchPropertyValue(action, 'breakfast'),
        lunch: fetchPropertyValue(action, 'lunch'),
        dinner: fetchPropertyValue(action, 'dinner'),
        medicines_taken: fetchPropertyValue(action, 'medicines_taken'),
        multivitamins_taken: fetchPropertyValue(action, 'multivitamins_taken'),
        exercise: fetchPropertyValue(action, 'exercise'),
        feeling_throughout_day: fetchPropertyValue(action, 'feeling_throughout_day'),
        stress_level: fetchPropertyValue(action, 'stress_level'),
        feeling_at_bedtime: fetchPropertyValue(action, 'feeling_at_bedtime'),
      };
    },
    getHealthLogInProgress: (state) => {
      state.formData = { ...emptyFormData };
    },
    getHealthLogFailed: (state) => {
      state.formData = { ...emptyFormData };
      state.error = {
        message: 'Failed to fet the health log data',
      };
    },
    saveHealthLog: (state, action) => {
      if (action.payload.date) {
        state.formData.date = action.payload.date;
        state.formData[action.payload.fieldName] = action.payload.text;
        state.saveInProgressForFormField = action.payload.fieldName;
      }
    },
    saveHealthLogSuccess: (state, action) => {
      console.log('saveHealthLogSuccess called, payload:', action.payload);
      state.saveInProgressForFormField = '';
      if (state.failedRequests[action.payload.fieldName]) {
        delete state.failedRequests[action.payload.fieldName];
      }
    },
    saveHealthLogFail: (state, action) => {
      console.log('saveHealthLogFail called, state:', state, ', action:', action);
      state.error.message = action.payload.errorMessage;
      state.failedRequests[action.payload.fieldName] = {
        error: action.payload.errorMessage,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getHealthLog,
  getHealthLogInProgress,
  getHealthLogFailed,
  saveHealthLog,
  saveHealthLogSuccess,
  saveHealthLogFail,
} = dailyHealthLogSlice.actions;

export default dailyHealthLogSlice.reducer;
