import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_HEALTH_LOG_DATA,
  SAVE_HEALTH_LOG,
  getHealthLog,
  getHealthLogInProgress,
  getHealthLogFailed,
  saveHealthLog,
  saveHealthLogFail,
  saveHealthLogSuccess,
} from './daily-health-log.reducer';
import { get } from 'lodash';

import { apiGetHealthLogData, apiSaveHealthLog } from './daily-health-log.api';
import { actionTypes } from '../../store/actions/base-actions';

function* sagaGetHealthLogData({ payload: { date } }) {
  try {
    yield put(getHealthLogInProgress());
    const response = yield call(apiGetHealthLogData, {
      date,
    });
    const responseData = get(response, 'data.data[0]', { entry_date: date });
    yield put(getHealthLog({ data: responseData }));
  } catch (error) {
    yield put(getHealthLogFailed());
  }
}

function* sagaSaveHealthLog({ payload: { date, fieldName, text } }) {
  try {
    // console.log('sagaSaveHealthLog', date, fieldName, text);
    yield put(
      saveHealthLog({
        date,
        fieldName,
        text,
      }),
    );
    const response = yield call(apiSaveHealthLog, {
      date,
      fieldName,
      text,
    });

    yield put(saveHealthLogSuccess({ status: response.status }));
    yield put({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: {
        message: 'Successfully saved the update',
        severity: 'success',
        autoHideDuration: 3000,
      },
    });
  } catch (error) {
    yield put(
      saveHealthLogFail({
        errorMessage: error.message,
        fieldName,
      }),
    );
    yield put({
      type: actionTypes.SHOW_NOTIFICATION,
      payload: {
        message: 'Failed to save the update',
        severity: 'error',
        autoHideDuration: 5000,
      },
    });
  }
}

function* sagaDailyHealthLog() {
  yield takeLatest(SAVE_HEALTH_LOG, sagaSaveHealthLog);
  yield takeLatest(GET_HEALTH_LOG_DATA, sagaGetHealthLogData);
}

export default sagaDailyHealthLog;
