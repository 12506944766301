import { createSlice } from '@reduxjs/toolkit';
import { searchTransactions } from './utils';
import moment from 'moment';

export const SET_FILTERS = 'SET_FILTERS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIZATION_RULES = 'GET_CATEGORIZATION_RULES';
export const UPSERT_CATEGORIZATION_RULES = 'UPSERT_CATEGORIZATION_RULES';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const FILTER_TRANSACTIONS = 'FILTER_TRANSACTIONS';
export const GET_REPORT_BY_CATEGORY = 'GET_REPORT_BY_CATEGORY';
export const GET_TIMELINE_REPORT_ON_CATEGORY = 'GET_TIMELINE_REPORT_ON_CATEGORY';

const initialState = {
  transactions: [],
  filteredTransactions: [],
  filters: {
    month: moment().format('MMM'),
    year: moment().format('YYYY'),
    categories: [],
    searchString: '',
  },
  actionInProgress: false,
  actionCompleted: false,
  error: {
    message: '',
  },
  currentReport: {
    reportData: [],
    transactionId: '',
    reportTransactions: [],
  },
  categories: [],
  categorizationRules: [],
};

export const expenseManagerSlice = createSlice({
  name: 'expenseManager',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };

      /* if (action.payload.filterData) {
        const {
          categories,
          searchString,
        } = state.filters;

        const doNotFilterByCategory =
          categories.length === 0 || (categories.length === 1 && categories[0].value === 'select-all');
        if (!searchString && doNotFilterByCategory) {
          state.filteredTransactions = state.transactions;
          state.actionInProgress = false;
          return;
        }

        state.filteredTransactions = searchTransactions({
          transactions: state.transactions,
          searchString,
          categoryFilter: categories.map((category) => category.title),
        });
        state.actionInProgress = false;
      } */
    },
    getTransactionsProgress: (state) => {
      state.actionInProgress = true;
      state.actionCompleted = false;
    },
    getTransactionsSuccess: (state, action) => {
      const { categories, searchString } = state.filters;
      state.transactions = action.payload.transactions || [];

      const doNotFilterByCategory =
        categories.length === 0 || (categories.length === 1 && categories[0].value === 'select-all');

      if (!searchString && doNotFilterByCategory) {
        state.filteredTransactions = state.transactions;
      } else {
        state.filteredTransactions = searchTransactions({
          transactions: state.transactions,
          searchString,
          categoryFilter: categories.map((category) => category.title),
        });
      }

      state.actionInProgress = false;
      state.actionCompleted = true;
    },
    getTransactionsFailed: (state) => {
      state.transactions = [];
      state.filteredTransactions = [];
      state.actionInProgress = false;
      state.actionCompleted = true;
      state.error = {
        message: 'Failed to fet the health log data',
      };
    },
    setActionInProgress: (state) => {
      state.actionInProgress = true;
    },
    actionFilterTransactions: (state) => {
      const { categories, searchString } = state.filters;
      // const { searchString, categories = [] } = action.payload;
      const doNotFilterByCategory =
        categories.length === 0 || (categories.length === 1 && categories[0].value === 'select-all');
      if (!searchString && doNotFilterByCategory) {
        state.filteredTransactions = state.transactions;
        state.actionInProgress = false;
        return;
      }

      state.filteredTransactions = searchTransactions({
        transactions: state.transactions,
        searchString,
        categoryFilter: categories.map((category) => category.title),
      });
      state.actionInProgress = false;
    },
    getCategoriesSuccess: (state, action) => {
      state.categories = action.payload.categories;
    },
    getCategorizationRulesSuccess: (state, action) => {
      state.categorizationRules = action.payload.categorizationRules;
    },
    fetchCurrentReportSuccess: (state, action) => {
      // console.log('fetchCurrentReportSuccess action.payload', action.payload);
      state.currentReport = {
        reportData: action.payload.reportData,
        transactionId: action.payload.transactionId,
        reportTransactions: [],
      };
      state.actionInProgress = false;
    },
  },
});

export const {
  setActionInProgress,
  // getTransactionsProgress,
  getTransactionsSuccess,
  // getTransactionsFailed,
  actionFilterTransactions,
  getCategoriesSuccess,
  getCategorizationRulesSuccess,
  updateFilters,
  fetchCurrentReportSuccess,
} = expenseManagerSlice.actions;

export default expenseManagerSlice.reducer;
