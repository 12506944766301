import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const ExpenseManager = () => {
  console.log('location:', useLocation());
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (navItem) => {
    setAnchorEl(null);
    navigate(`/expense-manager/${navItem}`);
  };

  const buttonVariation = (pathsToMatch) => {
    for (const path of pathsToMatch) {
      if (path.includes(location.pathname)) {
        console.log(`path: ${path} contains: ${location.pathname}`);
        return 'contained';
      }
    }
    return 'text';
  };

  const handleButtonClick = (e) => {
    navigate(`/expense-manager/${e.target.name}`);
  };

  return (
    <>
      <Stack spacing={2} mb={4} direction="row" w="100%">
        <Button
          name="upload-files"
          variant={buttonVariation(['/expense-manager', '/upload-files'])}
          onClick={handleButtonClick}
        >
          Upload data
        </Button>
        <Button
          name="review-transactions"
          variant={buttonVariation(['/review-transactions'])}
          onClick={handleButtonClick}
        >
          Review
        </Button>
        <Button name="categories" variant={buttonVariation(['/categories'])} onClick={handleButtonClick}>
          Categories
        </Button>
        <Button
          id="expense-manager-reports"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Reports
        </Button>
        <Menu
          id="expense-manager-reports-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem name="report-by-category" onClick={() => handleMenuItemClick('reports/report-by-category')}>
            Report by Category
          </MenuItem>
          <MenuItem name="timeline-on-category" onClick={() => handleMenuItemClick('reports/timeline-on-category')}>
            Timeline on Category
          </MenuItem>
        </Menu>
        {/* <Button
          id="expense-manager-categories"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Categories
        </Button>
        <Menu
          id="expense-manager-categories-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem name="report-by-category" onClick={() => handleMenuItemClick('categories')}>
            Categories
          </MenuItem>
          <MenuItem name="timeline-on-category" onClick={() => handleMenuItemClick('categorization-rules')}>
            Categorization rules
          </MenuItem>
        </Menu> */}
      </Stack>

      <Outlet />
    </>
  );
};

export default ExpenseManager;
