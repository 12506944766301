import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import HealthLogInput from './components/health-log-input/health-log-input';
// import HealthLogInput from '../../components/HealthLogInput/HealthLogInputV2';
import {
  GET_HEALTH_LOG_DATA,
  SAVE_HEALTH_LOG
} from './daily-health-log.reducer';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import { debounce } from "lodash";

const DailyHealthLog = () => {
  const dispatch = useDispatch();
  const {
    formData,
    saveInProgressForFormField,
    failedRequests
  } = useSelector((state) => state.dailyHealthLog);
  const [selectedDate, handleDateChange] = useState(dayjs());

  useEffect(() => {
    dispatch({
      type: GET_HEALTH_LOG_DATA,
      payload: {
        date: dayjs(selectedDate).format('YYYY-MM-DD')
      }
    });
  }, [selectedDate]);

  const onDateChange = (newDate) => {
    handleDateChange(newDate);
  };

  /* const onFormDataChange = debounce((e) => {
    console.log(
      `onFormDataChange e.target.name: ${e.target.name}, e.target.value: ${e.target.value}`
    );
    dispatch({
      type: SAVE_HEALTH_LOG,
      payload: {
        fieldName: e.target.name,
        text: e.target.value,
        date: selectedDate,
      },
    });
  }, 400); */
  const onFormDataChange = (e) => {
    dispatch({
      type: SAVE_HEALTH_LOG,
      payload: {
        fieldName: e.target.name,
        text: e.target.value,
        date: selectedDate
      }
    });

    document.querySelector(`textarea[name="${e.target.name}"]`).focus();
  };

  const handleChangeDay = (direction) => {
    if (direction === 'next') {
      handleDateChange(dayjs(selectedDate).add(1, 'day'));
    } else {
      handleDateChange(dayjs(selectedDate).subtract(1, 'day'));
    }
  };

  return (
    <Stack spacing={2} mb={'2rem'}>
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '1rem'
        }}
      >
        <h1>Daily Health Log</h1>
      </Box>
      <form>
        <Box>
          <Stack direction="row" spacing={2} justifyContent="flex-start" mt={4}>
            <Box width="50px" display="flex" justifyContent="center"
                 alignItems="center">
              {
                <Tooltip title="Previous day">
                  <span>
                  <IconButton
                    onClick={() => handleChangeDay('prev')}>
                    <SkipPreviousIcon />
                  </IconButton>
                  </span>
                </Tooltip>
              }
            </Box>
            <Box>
              <DatePicker
                autoOk
                variant="inline"
                label="Entry date"
                name="date"
                value={selectedDate}
                onChange={onDateChange}
              />
            </Box>
            <Box width="50px" display="flex" justifyContent="center"
                 alignItems="center">
              {
                <Tooltip title="Next day">
                  <span>
                  <IconButton
                    disabled={dayjs(selectedDate).isSame(dayjs(), 'day')}
                    onClick={() => handleChangeDay('next')}>
                    <SkipNextIcon />
                  </IconButton>
                  </span>
                </Tooltip>
              }
            </Box>
          </Stack>
        </Box>
        <Stack direction="row" spacing={2} justifyContent="space-between"
               mt={4}>
          <Box width="45%">
            <HealthLogInput
              id="morning_status"
              name="morning_status"
              label="How you feel in the morning"
              defaultValue={formData.morning_status}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'morning_status'}
              saveFailed={failedRequests.morning_status}
              errorMessage={failedRequests.morning_status?.error}
            />
          </Box>
          <Box width="45%">
            <HealthLogInput
              defaultValue={formData.sleep_pattern}
              id="sleep_pattern"
              name="sleep_pattern"
              label="Sleep pattern"
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'sleep_pattern'}
              saveFailed={failedRequests.sleep_pattern}
              errorMessage={failedRequests.sleep_pattern?.error}
            />
            {/* <TextField
                id="sleep_pattern"
                name="sleep_pattern"
                label="Sleep pattern"
                multiline
                rows={4}
                defaultValue={formData.sleep_pattern}
              /> */}
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between"
               mt={2}>
          <Box width="30%">
            <HealthLogInput
              id="breakfast"
              name="breakfast"
              label="Breakfast"
              defaultValue={formData.breakfast}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'breakfast'}
              saveFailed={failedRequests.breakfast}
              errorMessage={failedRequests.breakfast?.error}
            />
          </Box>
          <Box width="30%">
            <HealthLogInput
              id="lunch"
              name="lunch"
              label="Lunch"
              defaultValue={formData.lunch}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'lunch'}
              saveFailed={failedRequests.lunch}
              errorMessage={failedRequests.lunch?.error}
            />
          </Box>
          <Box width="30%">
            <HealthLogInput
              id="dinner"
              name="dinner"
              label="Dinner"
              defaultValue={formData.dinner}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'dinner'}
              saveFailed={failedRequests.dinner}
              errorMessage={failedRequests.dinner?.error}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between"
               mt={2}>
          <Box width="30%">
            <HealthLogInput
              id="medicines_taken"
              name="medicines_taken"
              label="Medicines taken"
              defaultValue={formData.medicines_taken}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'medicines_taken'}
              saveFailed={failedRequests.medicines_taken}
              errorMessage={failedRequests.medicines_taken?.error}
            />
          </Box>
          <Box width="30%">
            <HealthLogInput
              id="multivitamins_taken"
              name="multivitamins_taken"
              label="Supplements taken"
              defaultValue={formData.multivitamins_taken}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField ===
                              'multivitamins_taken'}
              saveFailed={failedRequests.multivitamins_taken}
              errorMessage={failedRequests.multivitamins_taken?.error}
            />
          </Box>
          <Box width="30%">
            <HealthLogInput
              id="exercise"
              name="exercise"
              label="Exercise"
              defaultValue={formData.exercise}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'exercise'}
              saveFailed={failedRequests.exercise}
              errorMessage={failedRequests.exercise?.error}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between"
               mt={2}>
          <Box width="30%">
            <HealthLogInput
              id="feeling_throughout_day"
              name="feeling_throughout_day"
              label="Feeling through the day"
              defaultValue={formData.feeling_throughout_day}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField ===
                              'feeling_throughout_day'}
              saveFailed={failedRequests.feeling_throughout_day}
              errorMessage={failedRequests.feeling_throughout_day?.error}
            />
          </Box>
          <Box width="30%">
            <HealthLogInput
              id="stress_level"
              name="stress_level"
              label="Stress level"
              defaultValue={formData.stress_level}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField === 'stress_level'}
              saveFailed={failedRequests.stress_level}
              errorMessage={failedRequests.stress_level?.error}
            />
          </Box>
          <Box width="30%">
            <HealthLogInput
              id="feeling_at_bedtime"
              name="feeling_at_bedtime"
              label="Feeling at bedtime"
              defaultValue={formData.feeling_at_bedtime}
              onChange={onFormDataChange}
              saveInProgress={saveInProgressForFormField ===
                              'feeling_at_bedtime'}
              saveFailed={failedRequests.feeling_at_bedtime}
              errorMessage={failedRequests.feeling_at_bedtime?.error}
            />
          </Box>
        </Stack>
      </form>
    </Stack>
  );
};

export default DailyHealthLog;
