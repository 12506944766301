import React, { useEffect, useState } from 'react';
import { HorizontalBarChart } from '../../../components/charts/horizontal-bar-chart';
// import MonthYearSelector from '../../../components/month-year-selector/month-year-selector';
import ReportTimePeriodSelector from '../../../components/report-time-period-selector/report-time-period-selector';
import { Box, Stack } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { GET_REPORT_BY_CATEGORY } from '../expense-manager.reducer';
import { createReportData } from '../utils';
import { ThreeCircles } from 'react-loader-spinner';
import Modal from '@mui/material/Modal';
import lodash from 'lodash';

const containerVariants = {
  hidden: {
    x: '-100vw',
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      delay: 0.25,
    },
  },
  exit: {
    x: '+100vw',
    transition: { ease: 'easeInOut' },
  },
};

/* const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
}; */
const incomeReportOptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Income Report',
    },
    tooltip: {
      intersect: true,
      position: 'nearest',
    },
  },
  scales: {
    x: {
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          // console.log('value:', value, ', index:', index, ', ticks:', ticks);
          return '$' + value;
        },
      },
    },
    y: {
      ticks: {
        autoSkip: false,
        // Include a dollar sign in the ticks
        callback: function (value) {
          // console.log('value:', value, ', index:', index, ', ticks:', ticks);
          return this.getLabelForValue(value);
        },
      },
    },
  },
};
const expenseReportOptions = lodash.cloneDeep(incomeReportOptions);
lodash.set(expenseReportOptions, 'plugins.title.text', 'Expense Report');
lodash.set(expenseReportOptions, 'plugins.tooltip.intersect', false);

export default function ReportByCategory() {
  const [reportType, setReportType] = useState('grouped-by-category');
  const [showDrillDown, setShowDrillDown] = useState(false);
  /* const [dateValues, setDateValues] = useState({
    month: moment().format('MMM'),
    year: moment().format('YYYY'),
  }); */
  const dispatch = useDispatch();
  const { currentReport, actionInProgress } = useSelector((state) => state.expenseManager);
  // console.log('dateValues:', dateValues);
  const incomeReportData = createReportData({
    inputData: currentReport.reportData.filter((item) => item.category_total >= 0),
  });
  const expenseReportData = createReportData({
    inputData: currentReport.reportData.filter((item) => item.category_total < 0),
  });
  console.log('actionInProgress:', actionInProgress);

  useEffect(() => {
    refreshData({ timePeriod: 'current-month' });
  }, [reportType]);

  /* const refreshData = () => {
    if (reportType === 'grouped-by-category') {
      dispatch({
        type: GET_REPORT_BY_CATEGORY,
        filters: { ...dateValues },
      });
    }
  }; */

  const refreshData = ({ timePeriod }) => {
    console.log('refreshData timePeriod:', timePeriod);
    dispatch({
      type: GET_REPORT_BY_CATEGORY,
      filters: { timePeriod },
    });
  };

  /* const handleDateChange = (newValue) => {
    console.log('in handleDateChange newValue:', newValue);
    setDateValues({ ...dateValues, ...newValue });
  }; */

  const handleReportTypeChange = (evt) => {
    setReportType(evt.target.value);
  };

  const handleDrillDown = ({ elementAtEvent, category }) => {
    console.log('handleDrillDown category:', category);
    setShowDrillDown(true);
  };

  return (
    <>
      <h1> Report by Category</h1>
      <Stack flexDirection="row" justifyContent="flex-end" width="100%" mb="1rem">
        <Box>
          <FormControl
            sx={{
              minWidth: 250,
            }}
            size="small"
          >
            <InputLabel id="report-type-selector-label">Report type</InputLabel>
            <Select
              labelId="report-type-selector-label"
              id="report-type-selector"
              value={reportType}
              label="Report type"
              onChange={handleReportTypeChange}
            >
              <MenuItem value="">
                <em>Select a report type</em>
              </MenuItem>
              <MenuItem value={'grouped-by-category'}>By Category</MenuItem>
              <MenuItem value={'grouped-by-sub-category'}>By Sub-category</MenuItem>
              <MenuItem value={'third-type'}>Third type</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <ReportTimePeriodSelector defaultTimePeriod="current-month" refreshData={refreshData} />
        {/* <MonthYearSelector
            month={dateValues.month}
            year={dateValues.year}
            handleDateChange={handleDateChange}
            refreshData={refreshData}
          /> */}
      </Stack>
      {/* <Box width="100%" height="auto" border="1px dashed red">
        {reportData && reportData.labels && reportData.datasets ? (
          <HorizontalBarChart data={reportData} options={options} />
        ) : null}
      </Box> */}
      {actionInProgress ? (
        <Box width="100%" height="300px" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <ThreeCircles
            height="200"
            width="200"
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </Box>
      ) : (
        <AnimatePresence mode={'wait'}>
          <motion.div
            className="container"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            minheight="500px"
            height={currentReport.reportData.length > 0 ? `${currentReport.reportData.length * 15}px` : '500px'}
            width="100%"
          >
            {incomeReportData && incomeReportData.labels && incomeReportData.datasets ? (
              <Stack flexDirection="column" gap="4rem" marginBottom="2rem">
                <HorizontalBarChart
                  data={incomeReportData}
                  options={incomeReportOptions}
                  handleOnClick={handleDrillDown}
                />
                <HorizontalBarChart
                  data={expenseReportData}
                  options={expenseReportOptions}
                  handleOnClick={handleDrillDown}
                />
              </Stack>
            ) : (
              <h3>{'No data found for the selected period'}</h3>
            )}
          </motion.div>
        </AnimatePresence>
      )}
      <Modal
        open={showDrillDown}
        onClose={() => setShowDrillDown(false)}
        aria-labelledby="drill-down-modal"
        aria-describedby="drill-down-modal"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '80%',
            height: '80%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'background.paper',
            border: '2px solid #DDD',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="drill-down-modal">Drill down</h2>
        </Box>
      </Modal>
    </>
  );
}
