/* eslint-disable react/prop-types */
import { transactionsTableColumns } from '../utils';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

const TransactionsTable = ({
  transactions,
  checkboxSelection,
  disableRowSelectionOnClick,
  rowSelectionModel,
  setRowSelectionModel,
}) => {
  return (
    <DataGrid
      rows={transactions}
      columns={transactionsTableColumns}
      getRowId={(row) => row.transaction_id}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      onCellEditStop={(params, event, details) => {
        console.log('onCellEditStop params:', params);
        console.log('onCellEditStop event:', event);
        console.log('onCellEditStop details:', details);
      }}
    />
  );
};

export default TransactionsTable;
