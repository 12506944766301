/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { Box, Paper, FormControlLabel, Divider } from '@mui/material';
import lodash from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AutoCompleteV2({ options, label, onChange, multiSelect = false }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleToggleSelectAll = () => {
    const selectAllOption = { title: 'All items selected', value: 'select-all' };
    setSelectAll((prev) => {
      if (!prev) setSelectedOptions([selectAllOption]);
      else setSelectedOptions([]);
      onChange([selectAllOption]);
      return !prev;
    });
  };

  const handleSelectChange = (_evt, value, reason) => {
    if (reason === 'clear' || reason === 'removeOption') {
      setSelectAll(false);
    }

    if (reason === 'selectOption' && value.length === options.length) {
      setSelectAll(true);
    }

    // if 'select-all' is selected, then don't update the selectedOptions
    if (selectedOptions.length === 1 && selectedOptions[0].value === 'select-all') {
      return;
    }

    setSelectedOptions(value);
    onChange(value);
  };

  const renderSelectAllComponent = (paperProps) => {
    const { children, ...restPaperProps } = paperProps;
    return (
      <Paper {...restPaperProps}>
        <Box
          onMouseDown={(e) => e.preventDefault()} // prevent blur
          pl={1.5}
          py={0.5}
        >
          <FormControlLabel
            onClick={(e) => {
              e.preventDefault(); // prevent blur
              handleToggleSelectAll();
            }}
            label="Select all"
            control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
          />
        </Box>
        <Divider />
        {children}
      </Paper>
    );
  };

  const itemSelectedIndex = (option) => {
    if (!selectedOptions || selectedOptions.length === 0) return -1;
    return selectedOptions.findIndex((item) => item.value === option.value);
  };

  const optionRenderer = (props, option, _state) => {
    return (
      <li {...props}>
        <Checkbox
          value={option.value}
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={itemSelectedIndex(option) > -1}
          disabled={selectAll}
        />
        {option.title}
      </li>
    );
  };

  return (
    <Autocomplete
      multiple={multiSelect}
      limitTags={2}
      id={`autocomplete-v2-${lodash.kebabCase(label)}`}
      options={options}
      value={selectedOptions}
      placeholder={label}
      disableCloseOnSelect={multiSelect}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.title || ''}
      renderOption={optionRenderer}
      style={{ width: 400 }}
      renderInput={(params) => <TextField {...params} label={label} placeholder={label} />}
      PaperComponent={multiSelect ? renderSelectAllComponent : null}
      onChange={handleSelectChange}
    />
  );
}

export default AutoCompleteV2;
